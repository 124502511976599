import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _get from 'lodash/get'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import Form from '../../components/forms/partials/NetlifyForm'
import Submit from '../../components/forms/partials/Submit'

const ManufacturersSignUpPage = ({ location }) => {
  const [activePlan, setActivePlan] = useState(
    _get(location.state, 'activePlan', 'annually')
  )

  const { markdownRemark } = useStaticQuery(graphql`
    query ManufacturersSignUpPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/manufacturers/sign-up" } }) {
        frontmatter {
          title
          form {
            turnoverOptions
            countries {
              value
              label
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const formData = pageData.form

  const breadcrumbs = [
    {
      name: 'For Manufacturers',
      uri: '/manufacturers',
    },
    {
      name: 'Sign up - Manufacturers',
      uri: '/manufacturers/sign-up',
    },
  ]

  return (
    <Page className={`p-sign-up p-forManufacturers has-fingerPrints`}>
      <SEO title={pageData.title} />
      <Schema breadcrumbs={breadcrumbs} />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <span className="c-pageHeader__title">
                    <strong>Sign up</strong>
                  </span>
                </div>
                <div className="col-12 col-lg-9">
                  <h1 className="c-pageHeader__intro">
                    <strong>Manufacturer</strong> Registration
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-3">
                  <Form
                    className="c-contactUsForm"
                    formName="Manufacturers Sign Up Form"
                    successPage="/sign-up/success"
                  >
                    <p className="mb-3 pb-3">
                      Please complete the form below and a member of our team
                      will be in touch to talk you through the sign up process.
                    </p>

                    <div className="form-group row">
                      <label
                        htmlFor="name"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Your name*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Your email*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="telephone"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Phone number*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="tel"
                          className="form-control"
                          id="telephone"
                          name="telephone"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label
                        htmlFor="billing_term"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Term*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <select
                          className="form-control"
                          id="billing_term"
                          name="billing_term"
                          required
                          value={activePlan}
                          onChange={(e) => setActivePlan(e.target.value)}
                        >
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="annually">Annually</option>
                        </select>
                        <small
                          id="billing_term_help"
                          className="form-text text-muted"
                        >
                          How would you like to be billed?
                        </small>
                      </div>
                    </div>

                    <div className="form-group row pt-4">
                      <div className="col-12">
                        <h2>Company Information</h2>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="company_legal_name"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Company name* <span>(legal trading name)</span>
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="company_legal_name"
                          name="company_legal_name"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="company_name"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Company name
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="company_name"
                          name="company_name"
                          placeholder="i.e if you trade under a different name/brand"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="company_registration_number"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Company registration number*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="company_registration_number"
                          name="company_registration_number"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label
                        htmlFor="company_turnover"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Annual turnover*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <select
                          className="form-control"
                          id="company_turnover"
                          name="company_turnover"
                          required
                        >
                          {formData.turnoverOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-group row pt-4">
                      <div className="col-12">
                        <h2>Company Address</h2>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="address_1"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Address line 1*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="address_1"
                          name="address_1"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="address_2"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Address line 2
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="address_2"
                          name="address_2"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="address_3"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Address line 3
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="address_3"
                          name="address_3"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="address_town"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Town*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="address_town"
                          name="address_town"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="address_county"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        County
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="address_county"
                          name="address_county"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="address_country"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Country*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <select
                          className="form-control"
                          id="address_country"
                          name="address_country"
                          required
                        >
                          {formData.countries.map(({ label, value }) => (
                            <option
                              key={value}
                              value={label}
                              disabled={value === 'DIVIDER'}
                            >
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label
                        htmlFor="address_post_code"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Postal code*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="address_post_code"
                          name="address_post_code"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row pt-4">
                      <div className="col-12">
                        <h2>Main Point of Contact</h2>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contact_firstname"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        First name*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="contact_firstname"
                          name="contact_firstname"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contact_surname"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Surname*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="contact_surname"
                          name="contact_surname"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contact_email"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Email address*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="email"
                          className="form-control"
                          id="contact_email"
                          name="contact_email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contact_telephone"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Landline telephone number
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="tel"
                          className="form-control"
                          id="contact_telephone"
                          name="contact_telephone"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contact_mobile"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Mobile telephone number
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="tel"
                          className="form-control"
                          id="contact_mobile"
                          name="contact_mobile"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contact_job"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Job title
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          id="contact_job"
                          name="contact_job"
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label
                        htmlFor="contact_comments"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
                      >
                        Additional comments
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
                        <textarea
                          className="form-control"
                          id="contact_comments"
                          name="contact_comments"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>

                    <Submit />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default ManufacturersSignUpPage
